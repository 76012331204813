import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`OTHER/WISE`}</h1>
    <h4>{`June 20–24, 2017 | Vinalhaven, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2srJaQV//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAQABBQIuSiWf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEhMRBBUf/aAAgBAQABPyHYm6KsEEio/9oADAMBAAIAAwAAABC8z//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQEBAQADAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8QUEK9dyNyzzn7d1dU7nkjGA+b/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OTHERWISE",
          "title": "OTHERWISE",
          "src": "/static/b3e55c4ee11800885624e7cfd6b99d0c/e5166/otherwise.jpg",
          "srcSet": ["/static/b3e55c4ee11800885624e7cfd6b99d0c/f93b5/otherwise.jpg 300w", "/static/b3e55c4ee11800885624e7cfd6b99d0c/b4294/otherwise.jpg 600w", "/static/b3e55c4ee11800885624e7cfd6b99d0c/e5166/otherwise.jpg 1200w", "/static/b3e55c4ee11800885624e7cfd6b99d0c/d9c39/otherwise.jpg 1800w", "/static/b3e55c4ee11800885624e7cfd6b99d0c/df51d/otherwise.jpg 2400w", "/static/b3e55c4ee11800885624e7cfd6b99d0c/768c6/otherwise.jpg 3264w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/photographing-other-wise-whether-events/" title="Photographing Other/Wise Whether/Events by Margo Halverson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/6174/" title="Drawing Other/Wise by Deb Hall" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/appropriate-technologies-for-the-new-millennium/" title="Appropriate Technologies for the New Millennium by Assaf Tamir, Stefani Bardin" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/perfect-storms-observations-gestures-moments-in-progress/" title="Perfect Storms : Observations / Gestures / Moments (in progress) by Emily Luce" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/index-of-previously-unrecorded-cloud-types-and-their-descriptions/" title="Index of Previously Unrecorded Cloud Types and Their Descriptions by Emily Luce, Nick Liadis" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/prompts-whether-conditions-whether-patterns-whether-events-in-progress/" title="Prompts: Whether Conditions / Whether Patterns / Whether Events (In Progress) by Emily Luce, Ken Botnick, NIck Liadis, Steve Bowden" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/annotated-bibliography-of-whether-references-a-work-in-progress/" title="Annotated Bibliography of Whether References (A Work in Progress) by Anita Cooney, Assaf Tamir, Charles Melcher, Chris Lee, Christopher Moore, Deb Hall, Emily Luce, Ken Botnick, Margo Halverson, NIck Liadis, Stefani Bardin, Steve Bowden, Susan Merritt" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/whether-station/" title="Whether Station by Emily Luce" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/remembering-designinquiry/" title="Remembering DesignInquiry by Susan Merritt" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`Projects that upend, invert, or otherwise challenge traditional notions of accomplishment as defined by one’s particular field or discipline — these we will call OTHER/WISE feats of elseways and production. Such projects could be, for example, an ongoing creative practice; part of a larger research agenda; a new direction in one’s work.`}</strong></p>
    <p><strong parentName="p">{`The OTHER/WISE call for proposals invited applications from artists, musicians, engineers, architects, scientists, designers, writers, directors, programmers, etc. who are curious, want to devote time to exploring areas beyond discipline confines, and who are amenable to un-mastery or embarking on a project that is (in the peripheral or) tangential to “X.” Field trips, wandering conversations, unhinged schedules, meal-happenings, are part of participating in a DesignInquiry residence’s OTHER/WISE-not-the-schedule.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      